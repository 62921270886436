$(document).ready(function () {
	$('[data-count=checkbox] input[type=checkbox]').on('change', function () {
		var $counters = $($(this).parents('[data-count=checkbox]'));
		
		$counters.each(function () {
			var total  = $(this).find('input[type=checkbox]:checked').length;
			var stotal = (total > 0) ? total : '';
			$($(this).data('target')).text(stotal);
		});

	});

});

