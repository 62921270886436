$(document).ready(function () {
	$('.input-tags').selectize({
			plugins: ['remove_button'],
			delimiter: ',',
			persist: false,
			create: function(input) {
			    return {
			        value: input,
			        text: input
			    }
			},
			render : {
				'option_create': function(data, escape) {
					return '<div class="create">Adicionar <strong>' + escape(data.input) + '</strong>&hellip;</div>';
				},
			}
	});

	$('.multiple-select').selectize({
		plugins: ['remove_button'],
		maxItems: 10
	});

	$('.single-select').selectize({
		create: false,
		sortField: 'text'
	})
	
});