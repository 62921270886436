/*
 *  
 *  Plugin de oragnização das tabelas do provas
 *  http://jqueryboilerplate.com
 *
 *  Made by Renan Azevedo
 */
// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;( function( $, window, document, undefined ) {

	"use strict";

		// undefined is used here as the undefined global variable in ECMAScript 3 is
		// mutable (ie. it can be changed by someone else). undefined isn't really being
		// passed in so we can ensure the value of it is truly undefined. In ES5, undefined
		// can no longer be modified.

		// window and document are passed through as local variable rather than global
		// as this (slightly) quickens the resolution process and can be more efficiently
		// minified (especially when both are regularly referenced in your plugin).

		// Create the defaults once
		var pluginName = "customTable",
			defaults = {
				'name': 'renan'
			};

		// The actual plugin constructor
		function Plugin ( element, options ) {
			this.element = element;

			// jQuery has an extend method which merges the contents of two or
			// more objects, storing the result in the first object. The first object
			// is generally empty as we don't want to alter the default options for
			// future instances of the plugin
			this.settings = $.extend( {}, defaults, options );
			this._defaults = defaults;
			this._name = pluginName;

			this.$ = $(this.element);

			this.init();
		}

		// Avoid Plugin.prototype conflicts
		$.extend( Plugin.prototype, {
			init: function() {
				this.bindEvents();
			},
			toggleSelectAll: function () {
			},
			bindEvents: function () {

				var $plugin = this.$;

				$.each(this.events, function (selector, eventFunction) {
					var eventType = selector.split(' ')[0];
					var selectorFiltered = selector.split(' ').slice(1).join(' ');

					$plugin.find(selectorFiltered).on(eventType, { scope: $plugin }, eventFunction );
				});

			},
			events: {
				'change thead tr input[type=checkbox]:first': function (event) {
					var stateNew = $(this).prop('checked');
					
					$(this).closest('table').find('tbody tr td:first-child input[type=checkbox]').prop('checked', stateNew);
				},
				'change tbody tr td:first-child input[type=checkbox]:first-child': function () {
					var $table = $(this).closest('table');
					var $thead = $table.find('thead tr input[type=checkbox]:first');
					var $tbody = $table.find('tbody tr td:first-child input[type=checkbox]:first-child');
					if($tbody.filter(':checked').length == $table.find('tbody tr').length)
						$($thead).prop('checked', true);
					else
						$($thead).prop('checked', false);

				},
				'click tbody tr': function () {
					$(this).find('td:first input[type=checkbox]').click();
				}
			}
		} );

		// A really lightweight plugin wrapper around the constructor,
		// preventing against multiple instantiations
		$.fn[ pluginName ] = function( options ) {
			return this.each( function() {
				if ( !$.data( this, "plugin_" + pluginName ) ) {
					$.data( this, "plugin_" +
						pluginName, new Plugin( this, options ) );
				}
			} );
		};

} )( jQuery, window, document );