$(document).ready(function(){
	$('[maxlength], [data-maxlength]').each(function(){
		var $this = $(this);
		
		var maxlength = $this.prop('maxlength') || $this.data('maxlength') || null;
		var target    = $this.data('target');
		var type = null;

		switch($this.get(0).tagName.toLowerCase()) {
			case 'input':
			case 'textarea':
				type = 'input'; break;
			default:
				type = 'div'; break;
		}

		var value = (type === 'input' ) ? $this.val(): $this.text();

		// Init:
		// Pré carrega de acordo com o conteúdo do campo
		if(target && maxlength) {
			$(target).text( (value.length || 0)+'/'+maxlength );
		} else {
			$(target).remove();
		}

		// Event:
		// Se possui maxlength, conta e atualiza o target determinado e/ou 
		// limita os caracteres.
		$(this).on('keyup', function(){
			var value, valueHtml;

			if(type === 'input') {
				value = $this.val() 
			}else {
				value = $this.text();
				valueHtml = $this.html();
			}

			if(value.length > maxlength) {
				(type === 'input') ? $this.val(value.substring(0, maxlength)) : $this.html(valueHtml.substring(0, maxlength))
			}

			if(target && maxlength){
				$(target).text( (value.length || 0)+'/'+maxlength );
			}

		})

	});
});

