window.QuestionSingleAnswerFormView = Backbone.View.extend({

	el: '.answers',
	letters: ['A','B','C','D','E','F','G','H','I','J'],
	answers: [],
	events: {
		'click .btn-add-answer': 'addOneAnswer'
	},

	initialize: function () {
		this.render();
		this.addOneAnswer().setCorrect();
		this.addOneAnswer();

		this.floatingBoxes();
	},

	render: function () {
		var $answers = this.$el;

		$answers.find('.answer-items').children().detach();

		this.answers.forEach(function(answerView){
			$answers.find('.asnwers-items').append(answerView.render().el);
		});
	},

	/*
	* Adiciona uma nova resposta ao formulário
	*/
	addOneAnswer: function (event, correct) {

		var model = new Backbone.Model({
			letter: this.letters[this.answers.length],
			index: this.answers.length+1,
			correct: ((correct) ? true : false),
			value: ''
		});

		var answerItemView = new QuestionSingleAnswerFormAnswerView({
			model: model
		});

		// Callback no model para atualizar as demais respostas
		this.listenTo(model, 'change:correct', this.uncorrectAll);
		this.listenTo(model, 'd', this.deleteAnswer);

		this.$el.find('.answer-items').append(answerItemView.render().el);

		this.answers.push(answerItemView);

		this.isLimitReached();

		if(event)
			answerItemView.activate().$el.find('textarea').focus();

		return answerItemView;
	},

	/*
	* Desativa todas os as opcões não corretas
	*/
	uncorrectAll : function (model) {
		var view = this;

		this.answers.forEach(function (answerView) {
			
			if(answerView.model === model) 
				return;

			// Limpa o listener temporariamente para atualizar o modelo
			view.stopListening(answerView.model, 'change:correct');

			// Atualiza o modelo
			answerView.model.set({ correct: false });

			// Adiciona novamente o listener
			view.listenTo(answerView.model, 'change:correct', view.uncorrectAll);

		});
	},

	/*
	* Ativa/desativa o botão de adicionar novas respostas
	*/
	isLimitReached: function () {
		var disabled = (this.answers.length >= this.letters.length) ? true: false;
		
		this.$el.find('.btn-add-answer')
			.prop('disabled', disabled)
			.toggleClass('disabled', disabled);

		return disabled;
	},

	/*
	* Remove uma view do array
	*/
	deleteAnswer: function (model) {
		var view = this;
		this.answers.forEach(function (answerView, index) {
			if(answerView.model === model) {
				var currentView = view.answers.splice(index,1)[0];
				currentView.remove();
			}
		});

		$('body, html').animate().finish();

		this.reorderAnswers();

		this.isLimitReached();
	},

	/*
	* Reseta as letras para ordenar de acordo com o array.
	*/
	reorderAnswers: function () {
		var view = this;

		this.answers.forEach(function (answerView, index) {
			answerView.model.set({ 
				letter: view.letters[index],
				index: index+1
			});
		});
	},

	/*
	* Scrolla o box da questão para seguir o scroll da página do desktop
	*/
	floatingBoxes: function () {
		var $floating = $('.floating-question');
		var $window   = $(window);
		
		var offset    = $floating.offset();

		$window.scroll(function () {
			if($window.width() > 769) {

				if($window.scrollTop() > offset.top) {
					$floating.stop().animate({
						marginTop: $window.scrollTop() - offset.top + 50
					});
				} else {
					$floating.stop().animate({
						marginTop: 0
					});
				}

			} else {
					$floating.stop().css({
						marginTop: 0
					});
			}
		});
	}

});