window.QuestionSingleAnswerFormAnswerView = Backbone.View.extend({
	className: 'answer-new',
	template: _.template(`
			<div class="answer-new-radio">
				<div class="answer-label-group">
					<div class="answer-label"><%= letter %></div>
					<div class="answer-radio <%= (correct) ? 'correct' : '' %>"></div>
				</div>
				<div class="answer-text">
					<textarea name="alternative_<%= index %>" id="" cols="3" class="form-control editor"><%= value %></textarea>
				</div>
			</div>
			<div class="btn-remove">Remover</div>
	`),

	events: {
		'click'               : 'activate',
		'click .answer-radio' : 'setCorrect',
		'click .btn-remove'   : 'delete',
		'change textarea' 		: 'setValue',
		'focus textarea' 		  : 'scroll'
	},

	initialize: function () {
		this.model.on('change', this.render, this);
	},

	render: function () {
		this.$el.html(this.template(this.model.toJSON()));
		return this;
	},

	activate: function (event) {
		$('.answer-new').removeClass('active');
		this.$el.addClass('active');

		return this;
	},

	scroll: function () {
		var view = this;

		if($(window).width() >= 769) {
			$('html, body').stop().animate({
				scrollTop: view.$el.offset().top - 50
			}, 400);
		}
	},

	setCorrect: function (event) {
		this.$el.closest('form').find('input[name=alternative_answer]').val(this.model.get('index'));
		this.model.set({ correct: true });
	},

	setValue: function (event) {
		this.model.set({ value : $(event.target).val() });
	},

	delete: function () {
		this.$el.closest('form').find('input[name=alternative_answer]').val('');
		this.model.trigger('d', this.model);
	}

});	