
$(document).ready(function() {
  $.ajaxSetup({ cache: true });
  $.getScript('//connect.facebook.net/en_US/sdk.js', function(){
    FB.init({
      appId: '694758037343441',
      version: 'v2.7'
    });     

    $('#login-facebook').click(function () {
	    FB.login(FBstatusChange, {scope: 'email,user_birthday'});
    });
  });

  // Toggle popover qu
  $('[data-hover=popover]').on('mouseenter', function () {
  	$(this).popover('show');
  }).on('mouseleave', function () {
  	$(this).popover('hide');
  })
});

var FBstatusChange = function (response) {
	if(response.status === 'connected') {
		FB.api('/me?fields=email,name,first_name,last_name,gender,birthday', function (response) {
			response.social_id      = response.id;
			response.social_network = 'facebook';
			delete response.id;

			$.ajax({
				url: 'http://localhost:3000/user/authenticate',
				dataType: 'json',
				type: 'POST',
				data: { user: response },
			}) 
			.done(function (data) {
				$.cookie('user:email' , data.email);
				$.cookie('user:id'    , data.id);
				$.cookie('user:token' , data.token);
			})
			.fail(function(e, msg){
			});

		});
	}
}

$(document).ready(function () {
	$('input[type=range]').rangeslider({polyfill: false});
	
	// Custom
	var customTable = $('table.table-custom');
	if(customTable.length)
		customTable.customTable();
});
