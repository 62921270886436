$(document).ready(function(){
	// Event:
	// $('.answer-new').click(function(){
	// 	$('.answer-new').removeClass('active');
	// 	$(this).addClass('active');
	// });
	
	// // Event:
	// $('.answer-radio').click(function(){
	// 	$('.answer-radio.correct').removeClass('correct');
	// 	$(this).addClass('correct');
	// });

	// // Event:	
	// $('.btn-add-answer').click(function(){
	// 	$(".answer-collection").append('<div class="answer-new"> <div class="answer-new-radio"> <div class="answer-label-group"> <div class="answer-label">A</div> <div class="answer-radio"></div> </div> <div class="answer-text"> <textarea name="" id="" cols="3" class="form-control editor"></textarea> </div> </div> </div>');
	// });


});
